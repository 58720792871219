<div>
    <h2 class="sub-header">
        Welfare State Analytics (WeStAc)<br/>Text Mining and Modeling Swedish Politics, Media & Culture, 1945-1989
    </h2>

    <div class="main-container">
        <div class="left-container">
            <p class="desc-text">
                <span class="sv">
                    Forskningsprojektet Välfärdsstaten analyserad. Textanalys och modellering av svensk 
                    politik, media och kultur, 1945-1989 – med den engelska akronymen WeStAc – är ett 
                    samarbete mellan Umeå universitet, Uppsala universitet, Lunds universitet och 
                    Kungliga biblioteket. Projektet har ett tvådelat syfte: att etablera system och 
                    rutiner för att digitalisera och kurera stora textmaterial som möter de krav som digital 
                    forskning ställer, samt att kartlägga diskursiva mönster i välfärdsårens texter.
                    <br/><br/>
                    I projektet digitaliseras och kureras texter i syfte att möta forskningskraven, 
                    samtidigt som projektets empiriska forskningsdel ger kontinuerlig feedback 
                    tillbaka till de instanser som digitaliserar och iordningställer texterna som dataset.
                </span>
                <br/><br/>
                The project will digitise literature, curate already digitised collections, 
                and perform research via probabilistic methods and text mining models. 
                WeStAc will both digitise and curate three massive textual datasets — in all, 
                Big Data of almost four billion tokens — from the domains of Swedish politics, 
                news media and literary culture during the second half of the 20th century.
                <br/><br/>
                WeStAc will establish a scholarly ecosystem of digitisation, 
                curation and research with a twofold objective: (A.) to develop digital curation 
                work, including the preparation of massive datasets for research at the library, 
                and (B.) to develop digital history scholarship and perform DH-inspired textual research.
            </p>
            <a class="explore-button" href="https://www.westac.se/" target="_blank">Project website ▶</a>

            <div class="box humlab">
                <h3>People @ Humlab</h3>
                <ul>
                    <li><a href="https://www.umu.se/personal/roger-mahler/">Roger Mähler</a></li>
                    <li><a href="https://www.umu.se/personal/fredrik-noren/">Fredrik Norén</a></li>
                    <li><a href="https://www.umu.se/personal/andreas-marklund/">Andreas Marklund</a></li>
                    <li><a href="https://www.umu.se/personal/tomas-skotare/">Tomas Skotare</a></li>
                    <li><a href="https://www.umu.se/personal/johan-malmstedt/">Johan Malmstedt</a></li>
            </ul>
            </div>

            <div class="box cooperation">
                <h3>In cooperation with</h3>
                <ul>
                    <li>Pelle Snickars</li>
                    <li>Johan Jarlbrink</li>
                    <li>Alexandra Borg</li>
                    <li>Erik Edoff</li>
                    <li>Måns Magnusson</li>
                    <li>Väinö Yrjänäinen</li>
                    
                </ul>
            </div>

            <div class="box financier">
                <h3>Financier</h3>
                <div class="financier-logo">
                    <a href="https://www.vr.se/" target="_blank">
                        <img src="assets/vr.png" alt="Swedish research council logotype">
                    </a>
                    <a class="big" href="https://www.umu.se/humlab/" target="_blank">
                        <img src="assets/umu-logo-EN.png" alt="Umeå University logotype">
                    </a>
                    <a href="https://www.uu.se" target="_blank">
                        <img src="assets/UU_logo.jpg" alt="Uppsala University logotype">
                    </a>
                    <a href="https://www.lu.se/start" target="_blank">
                        <img src="assets/LundUniversity-logo.png" alt="Lund University logotype">
                    </a>
                    <a href="https://www.kb.sei" target="_blank">
                        <img src="assets/KB-logo.png" alt="Kungliga biblioteket logotype">
                    </a>
                    <a href="https://www.rj.se/" target="_blank">
                        <img src="assets/riksbankens-jubileumsfond.png" alt="Riksdankens Jubileumsfond logotype">
                    </a>
                    <a href="https://www.vitterhetsakademien.se" target="_blank">
                        <img src="assets/Vitterhetsakademiens-logo.svg" alt="vitterhetsakademiens logotype">
                    </a>
                </div>
            </div>
        </div>

        <div class="right-container">
            <div class="project-image">
                <img class="screenshot-image" src="assets/westac-wordcloud.png" alt="Word clound from project data"/>
                <span>Word cloud created with data within project</span>
            </div>

            <p class="info-box">
                The dataset of “Politics” contains already digitised <span class="text-highlight">Swedish Governmental Reports (SOU)</span> and material from the Swedish Parliament, “Media” contains <span class="text-highlight">two digitised Swedish newspapers</span>, Aftonbladet and Dagens Nyheter, and “Culture” — which will be digitised — contains <span class="text-highlight">a literary journal, Bonniers Litterära magasin</span> and <span class="text-highlight">all Swedish novels from 1945 to 1989</span>.
            </p>
            <div class="project-image">
                <img class="screenshot-image" src="assets/SOU_kollage-1440x810.jpg" alt="Material within the project"/>
                <span>Material</span>
            </div>

        <div class="box tags">
                        <h3>Development techniques<!--Utvecklingsmetoder --></h3>
                        <div class="tag">
                            <span>Jupyter</span>
                            <span>Natural language processing</span>
                        </div>
                    </div>
        </div>
    </div>
</div>