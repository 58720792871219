import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tcoir',
  templateUrl: './tcoir.component.html',
  styleUrls: ['./tcoir.component.scss']
})
export class TcoirComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
