import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infravis-sead',
  templateUrl: './infravis-sead.component.html',
  styleUrls: ['./infravis-sead.component.scss']
})
export class InfravisSeadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
