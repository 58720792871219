<div>
    <h2 class="sub-header">DigiBön</h2>
    <div class="main-container">
        <div class="left-container">

            <p class="desc-text">
                En digital karta över Luleå stifts kyrkor och bönhus belyser 
                stiftets religiösa geografi genom att visa kyrkors och samfunds 
                lokaler i tid och rum. Lokalernas utbredning inbegriper framför 
                allt delar av kustlandet i Västerbottens och Norrbottens län samt 
                Tornedalen, områden som dessutom har lite olika religiös karaktär.
                <br/><span class="sv">Kartan är fortfarande under utveckling och kvaliteten 
                i underliggande data kommer att kontrolleras vartefter.</span>
                <br/><br/>Läs mer om 
                <a href="https://www.umu.se/nyheter/digital-karta-over-bonhus-visar-norrlandskt-bibelbalte_11671542/" 
                    target="_blank">projektet</a>
            </p>

            <a class="explore-button" href="http://digibon.humlab.umu.se" target="_blank">Testa Applikationen ▶</a>
            
            <div class="project-image">
                <img src="assets/digibon/digibon-screenshot.png" alt="Skärmklipp av kartan i en ArcGIS online applikation, 
                visar punkter på kartan över olika typer av bönhus">
                <span>Skärmklipp av kartan i en ArcGIS online-applikation</span>
            </div>

            <div class="box humlab">
                <h3>Personer på Humlab</h3>
                    <ul>
                        <li><a href="https://www.umu.se/personal/kajsa-palm/">Kajsa Palm</a></li>
                    </ul>              
            </div>

            <div class="box cooperation">
                <h3>Samarbetspartners</h3>
                <ul>
                    <li><a href="https://www.umu.se/personal/stefan-gelfgren/">Stefan Gelfgren</a></li>
                    <li><a href="https://www.umu.se/personal/cenk-demiroglu/">Cenk Demiroglu</a></li>
                </ul>
            </div>

<!--             <div class="box financier">
                <h3>Financier</h3>
                <div class="financier-logo">
                    <a href="https://" target="_blank">
                        <img src="assets/" alt="">
                    </a>
                </div>
            </div> -->
            
        </div>

        <div class="right-container">

            <div class="project-image">
                <img src="assets/digibon/digibon-screenshot-filter.png" alt="Applikationskartan visar filtreringsfunktion">
                <span>Skärmklipp av kartan med filteringsmöjlighet</span>
            </div>

            <p class="info-box">Kartan baseras på en <span>databas som bygger på bönhusinventeringar</span> som 
                gjorts av Länsstyrelsen i Västerbotten, Skellefteå museum och Norrbottens museum, 
                samt information som Svenska kyrkan har delgett om sina <span>kyrkor och kapell.</span>
            </p>

            <div class="project-image">
                <img src="assets/digibon/digibon-screenshot-time.png" alt="Applikationskartan visar tidsarpekt">
                <span>Skärmklipp av kartan med visualisering av utspridningen av bönhus över tid</span>
            </div>
        </div>
    </div>
</div>