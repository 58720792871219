import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-humlab-x',
  templateUrl: './humlab-x.component.html',
  styleUrls: ['./humlab-x.component.scss']
})
export class HumlabXComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
