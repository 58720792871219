<div>
    <!-- Header for project site -->
    <h2 class="sub-header">Swedish Historical Population Statistics<br/>SHiPS</h2>

    <!-- Main container to display content -->
    <div class="main-container">
        <div class="left-container">

            <!-- Introduction text to project -->
            <p class="desc-text">Insert text here</p>
            <!-- Button to explore application or website of project -->
            <a class="explore-button" href="http://ships.ddb.umu.se/?view_state=1085&application_name=ships" target="_blank">Explore Application ▶</a>
            
            <!-- Template for display of images from projects / Screenshots -->
            <div class="project-image">
                <img src="" alt="">
                <span>Image text</span>
            </div>

            <!-- List of people currently working @ Humlab,
                delete people not involved in project -->
            <div class="people-box humlab">
                <h3>People @ Humlab</h3>
                <ul>
                    <li><a href="https://www.umu.se/personal/johan-von-boer/">Johan von Boer</a></li>
                    <li><a href="https://www.umu.se/personal/marita-nilsson/">Marita Nilsson</a></li>
                </ul>
            </div>

            <!-- List of people previously worked @ Humlab
            Fill in list as needed. -->
            <div class="people-box previously">
                <h3>People previously working @ Humlab</h3>
                <ul>
                    <li>Fredrik Palm</li>
                </ul>
            </div>
        </div>

        <!-- Right side -->
        <div class="right-container">

            <div class="project-image">
                <img src="" alt="">
                <span>Image text</span>
            </div>

            <!-- Info box - Small text with background -->
            <p class="info-box">Insert text here</p>
            
            <!-- Videoframe for example Youtube videos of project -->
            <div class="videoframe">
                <iframe src="https://www.youtube.com/embed/kmNcoNoO2ec" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>