<div>
    <h2 class="sub-header">Melody</h2>
    <h4 class="year">2018–2021</h4>
    <div class="main-container">
        <div class="left-container">

            <p class="desc-text">
                MELODY är ett svar på Europeiska unionens handlingsplan för CBRN-händelser som 
                understryker medlemsstaternas plikt att förse samtliga drabbade med vård vid 
                en CBRN händelse. Syftet med projektet är därför att utveckla en samstämd 
                läroplan inom CBRN-träning för utrycknings- och sjukvårdspersonal inom EU.

                <br/><br/>Målsättningen med MELODY är att avgränsa, utveckla och förmedla 
                en läroplan för CBRN-träning, baserad på självupplevda behov och brister 
                hos aktiv utrycknings- och sjukvårdspersonal inom EU. Grundtanken är att 
                en gemensam insats för att förbereda utryckningspersonal för CBRN-händelser 
                ökar effektiviteten, samarbete över gränserna, och stärker den allmänna 
                beredskapen mot CBRN-hot i hela EU. Projektet består av tio samarbetspartners 
                från nio olika länder och leds av Dr. Carlos Rojas Palma, SCK•CEN, Belgien.
                <br/><br/><a href="https://www.umu.se/forskning/projekt/melody/" target="_blank">Läs mer på umu.se</a>
            </p>

            <a class="explore-button" href="" target="_blank">Upptäck Melodys hemsida ▶</a>

            <div class="box humlab">
                <h3>Personer på Humlab</h3>
                    <ul>
                        <li><a href="https://www.umu.se/personal/satish-stromberg/">Satish Strömberg</a></li>
                    </ul>             
            </div>

            <div class="box cooperation">
                <h3>Samarbetspartners</h3>
                <ul>
                    <li>Svenja Stöven</li>
                    <li><a href="https://www.umu.se/enheten-for-polisutbildning/" 
                        target="_blank">Enheten för polisutbildning</a></li>
                    <li>Europeiska CBRNE-centret </li>
                    <li>David Sjöberg</li>
                    <li>Lars-Erik Lauritz</li>
                    <li>Oscar Rantatalo</li>
                </ul>
            </div>

            <div class="box financier">
                <h3>Finansiärer</h3>
                <div class="financier-logo">
                    <a href="https://commission.europa.eu/about-european-commission/departments-and-executive-agencies/migration-and-home-affairs_sv" target="_blank">
                        <img src="assets/EU.jpg" alt="Europeiska Nationernas flagga">
                    </a>
                    <p>EU-kommissionens generaldirektorat för migration och inrikes frågor</p>
                </div>
            </div>
        </div>

        <!-- Right side -->
        <div class="right-container">

            <div class="project-image">
                <img src="assets/melody/melody.png" alt="Skärmklipp av Melodys hemsida">
                <span>Skärmklipp av hemsidan</span>
            </div>

            <!-- Info box - Small text with background -->
            <p class="info-box">Projektet ska översättas till 14 språk, varav fyra redan är klara.</p>
        </div>
    </div>
</div>