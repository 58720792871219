<div>
    <h2 class="sub-header">SEAD Conservation Paleobiology</h2>
    <div class="main-container">
        <div class="left-container">

            <p class="desc-text">
                <span class="sv">Infravis pilotprojekt av SEAD BugsCEP ecocodes visualiserade på två olika sätt. 
                    Genom ArcGIS men också ur ett mer konstnärligt perspektiv. 
                </span>
                <br/><br/>
                This is an Infravis pilot project that will demonstrate the availability of a long-term record of biodiverity data, from the SEAD database,
                which could be used to understand the biodiverity implications of the current climate and extinction crisis.
                <br/><br/>
                The project will create an online ArcGIS app using a space-time pattern mining methodology for an interactive visualization of
                changes in insect biodiverity, and its environmental implicaions, over space and time. 
                <!-- <br/><br/><a href="https://" target="_blank">Read more</a> -->
            </p>

<!--             <a class="explore-button" href="" target="_blank">Explore Application ▶</a>
 -->
            <div class="frameinteractive">
                <iframe  src="https://demo.humlab.umu.se/seadimagesphere/" title="Sead image sphares"></iframe>
                <p>Interactive representation of bugs ecocode made out of AI generated images.</p>
            </div>

            <div class="box humlab">
                <h3>People @ Humlab</h3>
<!--                 add this div class=two-col-people if previous humlabers should be included
 -->                <div class="two-col-people">
                    <ul>
                        <li><a href="https://www.umu.se/personal/carl-erik-engqvist/">Carl-Erik Engqvist</a></li>
                        <li><a href="https://www.umu.se/personal/johan-von-boer/">Johan von Boer</a></li>
                        <li><a href="https://www.umu.se/personal/kajsa-palm/">Kajsa Palm</a></li>
                        <li><a href="https://www.umu.se/personal/mattis-lindmark/">Mattis Lindmark</a></li>
                        <li><a href="https://www.umu.se/personal/roger-mahler/">Roger Mähler</a></li>
                        <li><a href="https://www.umu.se/personal/marita-nilsson/">Marita Nilsson</a></li>
                    </ul>

                    <ul>
                        <li><span>Previous</span></li>
                        <li><a href="https://www.umu.se/personal/cenk-demiroglu/">Cenk Demiroglu</a></li>
                    </ul>
                </div>                
            </div>

            <div class="box cooperation">
                <h3>In cooperation with</h3>
                <ul>
                    <li>Philip Buckland</li>
                </ul>
            </div>

<!--             <div class="box financier">
                <h3>Financier</h3>
                <div class="financier-logo">
                    <a href="https://" target="_blank">
                        <img src="assets/" alt="">
                    </a>
                </div>
            </div> -->

        </div>

        <!-- Right side -->
        <div class="right-container">

            <div class="project-image">
                <img src="assets/infravis-sead/bugs-treemap.png" alt="Tree map of BugsCEP ecocode of a SEAD site">
                <span>Tree map of BugsCEP ecocode of a SEAD site represented by AI generated images</span>
            </div>

            <!-- Info box - Small text with background -->
            <p class="info-box">
                The project also experiments with <span>AI generated images</span> representing SEAD Bugs ecododes in a creative and artistic way.
            </p>
            
            <!-- Videoframe for example Youtube videos of project -->
<!--             <div class="videoframe">
 -->        
            
<!--             </div>
 -->
            <div class="project-image">
                <img src="assets/infravis-sead/ecobugs.png" alt="ArcGIS application of ecocodes">
                <span>ArcGIS application of the SEAD Bugs ecocodes</span>
            </div>

            <div class="box infrastructure">
                <h3>Infrastructure</h3>
                <a href="http://infravis.se/" target="_blank">
                    <img src="assets/infravis.png" alt="infravis logotype"></a>
            </div>

            <div class="box tags">
                <h3>Development techniques</h3>
                <div class="tag">
                    <span>Midjourney</span>
                    <span>ArcGIS</span>
                    <span>Web Development</span>
                </div>
            </div>
        </div>
    </div>
</div>