<div>
    <h2 class="sub-header">Yrkesrum</h2>
    <h4 class="year">2018</h4>

    <div class="main-container">
        <div class="left-container">
            <p class="desc-text">
                Senare tids forskning har visat att regionala ekonomier förändras 
                    gradvis över tid. I princip går det att säga att regionala resurser 
                    (kompetenser, verksamheter eller teknologier) som är relaterade (komplementära) 
                    till redan existerande regionala resurser har större sannolikhet att utvecklas i 
                    en region jämfört med resurser som ligger längre ifrån nuvarande specialiseringar. 
                    Detta beror på att regionen kan återanvända de redan existerande kompetenserna 
                    i de nya aktiviteterna, vilket bland annat underlättar lokal kompetensförsörjning.
                    
            </p>
            <a class="explore-button" href="https://yrkesrum.humlab.umu.se/" target="_blank">Utforska Applikationen ▶</a>

            <div class="box humlab">
                <h3>People @ Humlab</h3>
                <ul>
                    <li>
                        <a href="https://www.umu.se/personal/johan-von-boer/">Johan von Boer</a>
                    </li>
                </ul>
            </div>

            <div class="box cooperation">
                <h3>In cooperation with</h3>
                <ul>
                    <li>Rikard Eriksson</li>
                    <li>Emelie Hane-Weijman</li>
                    <li><a href="http://https://www.umu.se/centrum-for-regionalvetenskap/" target="_blank">Centrum för regionalvetenskap</a></li>
                </ul>
            </div>
            <div class="box financier">
                <h3>Financier</h3>
                <div class="financier-logo">
                    <a href="https://mmw.wallenberg.org/" target="_blank">
                        <img src="assets/logoMMW.svgz" alt="Swedish research council logotype">
                    </a>
                    <a href="https://vr.se" target="_blank">
                        <img src="assets/vr.png" alt="Swedish research council logotype">
                    </a>
                </div>
            </div>
        </div>
        
        <div class="right-container">
            <div class="project-image">
                <img class="screenshot-image" src="assets/yrkesrum-screenshot-1.png" />
                <span>Network graph of occupational movement</span>
            </div>

            <p class="info-box">I applikationen redovisas Sveriges yrkesrum utifrån hur relaterade och komplexa yrken är. 
                Detta för att ge en fingervisning om var en <span>regions kompetensmässiga specialiseringar</span> 
                finns och därmed indikera potentiella regionala utvecklingsområden utifrån existerande styrkor.</p>
        </div>
    </div>
</div>