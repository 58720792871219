<div>
    <h2 class="sub-header">IAccept: Soft surveillance – between acceptance and resistance</h2>
    <h4 class="year">2017–2021</h4>

    <div class="main-container-one-col">
            <p class="desc-text">
                <span class="sv">
                    Övervakningsdiskussionen har de senaste åren nyanserats från att det enbart 
                    skulle ske ovanifrån, till att ses som något ständigt närvarande i alla 
                    lager av samhället. Den övervakning som sker av stat och myndigheter, 
                    också kallad "hård" övervakning, har debatterats i såväl riksdag som 
                    offentlighet. "Mjuk" övervakning däremot – som rör kommersiella och 
                    icke-kommersiella aktörers användande av detta material och hur allmänheten 
                    förhåller sig till detta – har diskuterats i mindre omfattning. Det är vad 
                    iAccept-projektet studerar.
                </span>
                <br/><br/>
                In recent years, the surveillance discussion has been nuanced from the fact that 
                it should only be seen from above, to be seen as something constantly present in 
                all layers of society. The surveillance conducted by state and government, also 
                known as "hard" surveillance, has been debated in both Parliament and the public. 
                On the other hand, "soft" monitoring – concerning the use of this material by 
                commercial and non-commercial actors and how the public relies on it – 
                has been discussed to a lesser extent. That is what the iAccept project explores. 
            </p>

            <a class="explore-button" href="https://www.umu.se/forskning/projekt/iaccept-soft-surveillance--between-acceptance-and-resistance/" 
            target="_blank">Read More ▶</a>

            <div class="box humlab">
                <h3>People @ Humlab</h3>
                <div class="two-col-people">
                    <ul>
                        <li><a href="https://www.umu.se/en/staff/coppelie-cocq/">Coppélie Cocq</a></li>
                    </ul>

                    <ul>
                        <li><span>Previous</span></li>
                        <li>Stefan Gelfgren</li>
                    </ul>
                </div>
            </div>

            <div class="box cooperation">
                <h3>In cooperation with</h3>
                <ul>
                    <li>Stefan Gelfgren</li>
                    <li>Jesper Enbom</li>
                    <li><a href="https://www.umu.se/institutionen-for-kultur-och-medievetenskaper/">Institutionen för kultur- och medievetenskaper, Umu</a></li>
                    <li>Lars Samuelsson</li>
                    <li><a href="https://www.umu.se/institutionen-for-ide-och-samhallsstudier/">Institutionen för idé- och samhällsstudier, Umu</a></li>
<!--                     <li><a href="https://www.umu.se/centrum-for-transdisciplinar-ai/">Centrum för transdisciplinär AI</a></li>
 -->                    <li><a href="https://www.umu.se/humanistisk-fakultet/">Humanistisk fakultet, Umu</a></li>
                </ul>
            </div>

            <div class="box financier">
                <h3>Financier</h3>
                <div class="financier-logo">
                    <a href="https://maw.wallenberg.org/" target="_blank">
                        <img src="assets/stiftelsenMAWM-logo.svgz" alt="Stiftelsen Marcus och Amalia Wallenbergs Minnesfond logo">
                    </a>
                </div>
            </div>

            <!-- <div class="box tags">
                <h3>Development techniques<!-Utvecklingsmetoder -></h3>
                <div class="tag">
                    <span>tags</span>
                    <span>tags</span>
                    <span>tags</span>
                </div>
            </div> -->
        
    </div>
</div>