<div>
    <h2 class="sub-header">ENIDA - Experiencing Nature in the Digital Age</h2>
    <h4 class="year">2019</h4>

    <div class="main-container">
        <div class="left-container">
            <p class="desc-text">
                <span class="sv">
                    Syftet med detta projekt är att undersöka på vilka sätt mediateknik har format användningen 
                    av natur för rekreation i Skandinavien sen slutet på 1800-talet. 
                    Projektet tittar speciellt på digital mediateknik sen 2000-talets början, men applicerar ett 
                    jämförande historiskt perspektiv för att placera dessa tekniker i en längre historisk kontext.
                </span>
                <br/><br/>
                Recreational uses of outdoor natural environments have become a fundamental part of 
                Scandinavian national identities since the late 1800s. Through leisure activities like 
                trekking and hunting, people gain access to particular ways of knowing and valuing nature, 
                developed over time as cultural traditions. More than anything else, such activities have 
                come to define what a natural landscape should look like and how it should be valued. 
                This project seeks to understand what happens when new and pervasive digital technologies 
                become part of this relationship with nature.
                <br /><br />
                Read more about the <a href="https://newnatures.org/experiencing-nature-in-the-digital-age/" target="_blank">project</a>
            </p>
            <a class="explore-button" href="https://enida.humlab.umu.se/" target="_blank">Explore Application ▶</a>
            
            <div class="box humlab">
                <h3>People @ Humlab</h3>
                <ul>
                    <li><a href="https://www.umu.se/personal/johan-von-boer/">Johan von Boer</a></li>
                    <li><a href="https://www.umu.se/personal/mattis-lindmark/">Mattis Lindmark</a></li>
                    <li><a href="https://www.umu.se/personal/kajsa-palm/">Kajsa Palm</a></li>
                </ul>
            </div>

            <div class="box cooperation">
                <h3>In cooperation with</h3>
                <ul>
                    <li>Finn Arne Jørgensen</li>
                    <li>Isabelle Brännlund</li>
                    <li>SLU</li>
                </ul>
            </div>

            <div class="box financier">
                <h3>Financier</h3>
                <div class="financier-logo">
                    <a href="https://formas.se/" target="_blank">
                        <img src="assets/formas-logo.png" alt="Formas logotype">
                    </a>
                </div>
            </div>
        </div>
        <div class="right-container">
            <div class="project-image">
                <img src="assets//enida/enida-screenshot-1.png" />
                <span>Screenshot</span>
            </div>
            <p class="info-box">The goal of the project is to uncover the ways in which media technologies have shaped recreational uses of 
                nature in <span>Scandinavia since the late 1800s.</span> The project will pay particular attention to <span>digital media 
                technologies post-2000,</span> but will apply a comparative historical perspective to place these technologies 
                in a longer historical context.
            </p>
            <div class="project-image">
                <img src="assets/enida/enida-screenshot-2.png" />
                <span>Screenshot</span>
            </div>
        </div>
    </div>
</div>