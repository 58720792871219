<div>
    <h2 class="sub-header">Utopia</h2>
    <h4 class="year">2017</h4>

    <div class="main-container">
        <div class="left-container">
            <p class="desc-text">
                Projektet använder spelifiering och barns delaktighet för att ta fram ett innovativt digitalt demokrativerktyg.
                I det interaktiva spelet Utopia World är utgångspunkten ”vi kommer längre och når högre om vi samarbetar”. 
                Projektets primära målgrupper är barn i åldrarna 9-13 (åk 4-6) samt aktörer som jobbar med demokratimetod- utveckling och teknologibaserad inlärning för barn. 
                <br/><br/>
                Läs mer på <a href="http://utopia-world.weebly.com" target="_blank">projektets webbplats</a>
            </p>

            <a class="explore-button" href="https://mattislindmark.github.io/testzone/UtopiaWGLbeta3/index.html" target="_blank">Testa spelet i webbläsaren ▶</a>        

            <div class="project-image">
                <img class="screenshot-image" src="assets/utopia/utopia_ar_bast.png" alt="Uppskrivet på whiteboard att 'Utopia är bäst!'"/>
                
                <span>Utopia är bäst!</span>
            </div>

            <div class="box humlab">
                <h3>People @ Humlab</h3>
                <ul>
                    <li><a href="https://www.umu.se/personal/mattis-lindmark/">Mattis Lindmark</a></li>
                    <li><a href="https://www.umu.se/personal/carl-erik-engqvist/">Carl-Erik Engqvist</a></li>
                    <li><a href="https://www.umu.se/personal/coppelie-cocq/">Coppélie Cocq</a></li>
                </ul>
            </div>

            <div class="box cooperation">
                <h3>In cooperation with</h3>
                <ul>
                    <li>Maria Elfving</li>
                    <li>Kulturverket</li>
                    <li>Kulturskolan</li>
                    <li>Sliperiet Örnsköldsvik</li>
                </ul>
            </div>

            <div class="box financier">
                <h3>Financier</h3>
                <div class="financier-logo">
                    <a href="https://www.arvsfonden.se/" target="_blank">
                        <img src="assets/allmanna-arvsfonden-logo.png" alt="Allmänna Arvsfonden logotype">
                    </a>
                    <a href="https://kulturverketumea.se/" target="_blank">
                        <img src="assets/kulturverket-logo.jpg" alt="Kulturverket Umeå logotype">
                    </a>
                    <a href="https://www.ornskoldsvik.se/" target="_blank">
                        <img src="assets/ornskoldsviks-kommun.png" alt="Örnsköldsviks kummun logotype">
                    </a>
                </div>
            </div>
        </div>

        <div class="right-container">                
            <div class="project-image">
                <img class="screenshot-image" src="assets/utopia/utopia.png" alt="Ett skärmklipp från spelet Utopia."/>
                <span>Screenshot från spelet Utopia</span>
            </div>
            <p class="info-box">
                Spelet erbjuder <span class="text-highlight">upplevelsebaserat lärande och deliberativ pedagogik.</span>
                Detta genom en kombination av ett analogt och digitalt spel med interaktiva inslag.     
            </p>

            <div class="project-image">
                <img class="screenshot-image" src="assets/utopia/hydrokvariatet.jpg" alt="Skärmklipp från spelet Utopia, varelser i dome under vatten."/>    
                <span>Screenshot från spelet Utopia</span>
            </div>

            <div class="project-image">
                <img class="screenshot-image" src="assets/utopia/utopiabeta-2016-07-22-155219_orig.jpg" alt="Skärmklipp från spelett Utopia, gränssnitt i rymden."/>    
                <span>Screenshot från spelet Utopia</span>
            </div>       
            
            <div class="box tags">
                            <h3>Development techniques<!--Utvecklingsmetoder --></h3>
                            <div class="tag">
                                <span>Unity</span>
                                <span>Game development</span>
                            </div>
                        </div>
        </div>
    </div>
</div>