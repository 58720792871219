<div>
    <h2 class="sub-header">Dialectical Imaginaries <br/> Art and identity in a European landscape</h2>
    <h4 class="year">2020–2021</h4>

    <div class="main-container-one-col">
            <div class="desc-text">
                Dialectical imaginaries vart ett konstnärligt samarbete mellan Miguel Hernández 
                University Altea, Spanien, Humlab och Umeå konsthögskola som pågick under 2020 
                till 2021. Detta samarbete inkluderade besök på respektive ort, utställningar, 
                utbyte av teori, kultur samt regelbundna möten per zoom och VR chat. 
                Mötena fortgår än idag.
                <br/><br/>
                Projektet hade som förutsättningar att med konstnärliga metoder formulera frågor 
                kring ämnen såsom:
                <ul>
                    <li>Våra olika geografiska positioner</li>
                    <li>Våra olika konstnärliga uttryck</li>
                    <li>Vår relation till vad som kan kallas centrum och periferi.</li>
                    <li>Hur vi som konstnärer kommunicerar våra resultat.</li>
                    <li>Hur vi fördjupar samarbetet mellan Altea och Umeå.</li>
                </ul>
                
                Projektet kulminerade i serie av texter och filmade intervjuer som främst 
                visats i en spansk kontext. En samlad publication är planerad att bli färdig under 2023.
            </div>

            <div class="project-image">
                <img src="assets/dialectical-imaginaries/di.png" alt="Example of concept made of Carl-Erik-Engqvist">
                <span>Example of concept made by Carl-Erik Engqvist</span>
            </div>

            <div class="box humlab">
                <h3>People @ Humlab<!-- Personer på Humlab --></h3>
                    <ul>
                        <li><a href="https://www.umu.se/personal/carl-erik-engqvist/">Carl-Erik Engqvist</a></li>
                    </ul>               
            </div>

            <div class="box cooperation">
                <h3>Samarbetspartners</h3>
                <ul>
                    <li></li>
                    <li><a href="https://www.umu.se/personal/micael-norberg/">Micael Norberg, Konsthögskolan Umeå Universitet</a></li>
                    <li>School of fine art, Altea</li>
                </ul>
            </div>

            <div class="box financier">
                <h3>Finansiär<!-- Finansiärer --></h3>
                <div class="financier-logo">
                    <p>Humanistisk fakultet, Umeå Universitet</p>
                </div>
            </div>

        
    </div>
</div>