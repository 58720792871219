import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialectical-imaginaries',
  templateUrl: './dialectical-imaginaries.component.html',
  styleUrls: ['./dialectical-imaginaries.component.scss']
})
export class DialecticalImaginariesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
