<div>
    <h2 class="sub-header">SEAD - The Strategic Environment Archaeology Database</h2>

    <div class="main-container">
        <div class="left-container">
<!--             <div class="project-logo"> 
                <object data="assets/sead/Sead-logo-animation.svg" type="image/svg+xml"></object>
            </div> -->
            <p class="desc-text">
                <span class="sv">SEAD, eller Strategic Environmental Archaeology Database, är en open access, 
                    internationellt orienterad och tvärvetenskaplig nationell forskningsdatainfrastruktur för 
                    lagring, hantering, spridning och analys av empiriska data om tidigare mänsklig aktivitet, 
                    biologisk mångfald och långsiktiga miljö- och klimatförändringar. Den utvecklas och 
                    hanteras av Miljöarkeologiska laboratoriet i samarbete med Humlab vid Umeå Universitet.
                    SEAD är en del i den nationella infrastrukturen för digital arkeologi (SveDigArk).</span>
                <br/><br/>
                SEAD, or the Strategic Environmental Archaeology Database, is a national research infrastructure 
                for archaeology and an international standard database for environmental archaeology data. 
                It is being developed and managed at the Environmental Archaeology Lab (MAL), 
                in collaboration with Humlab at Umeå University, Sweden. 
                SEAD is part of the Swedish National Infrastructure for Digital Archaeology (SweDigArch)
                <br/><br/><a href="https://sead.se" target="_blank">Read more about SEAD</a>
                <br/>
                <a href="http://swedigarch.se" target="_blank">Read more about SweDigArch</a>
            </p>
            
            <a class="explore-button" href="https://browser.sead.se" target="_blank">Explore Application ▶</a>
            
            <div class="project-image">
                <img src="assets/sead/sead-screenshot-4.png" alt="Graph showing species taxon distribution in archeological sample"/>
                <span>Graph of species taxon distribution in an archeological sample</span>
            </div>
            
            <div class="box humlab">
                <h3>People @ Humlab</h3>
                    <ul>
                        <li><a href="https://www.umu.se/personal/johan-von-boer/">Johan von Boer</a></li>
                        <li><a href="https://www.umu.se/personal/roger-mahler/">Roger Mähler</a></li>
                        <li><a href="https://www.umu.se/personal/kajsa-palm/">Kajsa Palm</a></li>
                        <li><a href="https://www.umu.se/personal/andreas-marklund/">Andreas Marklund</a></li>
                        <li><a href="https://www.umu.se/personal/rebecka-weegar/">Rebecka Weegar</a></li>
                    </ul>
            </div>

            <div class="box cooperation">
                <h3>In cooperation with</h3>
                <ul>
                    <li><a href="https://www.umu.se/en/staff/philip-buckland/">Philip Buckland</a></li>
                    <li><a href="https://www.umu.se/en/research/infrastructure/mal/">The Environmental Archaeology Lab</a></li>
                </ul>
            </div>

            <div class="box financier">
                <h3>Financier</h3>
                <div class="financier-logo">
                    <a href="https://www.vr.se/" target="_blank">
                        <img src="assets/vr.png" alt="Swedish research council logotype">
                    </a>
                    <a href="https://www.umu.se/humlab/" target="_blank">
                        <img src="assets/umu-logo-EN.png" alt="Umeå University logotype">
                    </a>
                </div>
            </div>
        </div>

        <div class="right-container">
            <div class="project-image">
                <img src="assets/sead/sead-screenshot-2.png" alt="SEAD user interface screenshot"/>
                <span>SEAD main interface</span>
            </div>
            
            <p class="info-box">
                The SEAD relational database contains <span>data essential to our understanding of past environmental and climatic changes</span>, and the human relationship with these changes. The data in SEAD primarily takes the form of biological and chemical/physical proxy data, i.e. fossil frequencies and measured variables derived from soil samples taken from archaeological and natural deposits.
            </p>
            <div class="project-image">
                <img src="assets/sead/sead-screenshot-5.png" alt="SEAD user interface site screenshot"/>
                <span>SEAD site <span>report</span></span>
            </div>

            <div class="box infrastructure">
                <h3>Infrastructure</h3>
                <a href="http://swedigarch.se/" target="_blank">
                    <img src="assets/swedigarch-logo.png" alt="SweDigArch logotype"></a>
            </div>


        </div>
    </div>
</div>