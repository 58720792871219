<div>
  <h2 class="sub-header tile"> 
    A showcase of current and completed projects at
    <a id="humlab" href="http://umu.se/humlab" target="_blank">Humlab</a>, Umeå
    University
  </h2>
  <div id="main-grid-container">
    <a href="/sead">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <img
            src="assets//sead/SEAD-logo-with-subtext.png"
            alt="SEAD logotype"
          />
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/sead/sead-screenshot.png"
          alt="Screenshot of SEAD tool"
        />
        <ul class="infrastructure-tags">
          <li class="swedigarch">SweDigArch</li>
        </ul>
      </div>
    </a>

    <a href="/visp">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <img
            class="project-logo"
            src="assets/visp/VISP-logo-black.png"
            alt="Visible Speech logotype"
          />
        </div>
        <img
          class="screenshot-cover rowspan"
          src="assets/visp/screenshot2.png"
          alt="Screenshot of tool, soundwaves"
        />
        <img
          class="screenshot-cover"
          src="assets/visp/screenshot4.png"
          alt="Screenshot of tool, interface"
        />
        <img
          class="screenshot-cover"
          src="assets/visp/screenshot3.png"
          alt="Screenshot of tool"
        />
        <ul class="infrastructure-tags">
          <li class="sweclarin">Swe-Clarin</li>
        </ul>
      </div>
    </a>

    <a href="/westac">
      <div class="project-grid-tile project-grid-tile-4-pane">
        <div class="project-header-container">
          <h3 style="font-family: monospace; text-align: left">
            Welfare state analytics
          </h3>
        </div>
        <img
          class="screenshot-cover"
          src="assets/westac/screenshot1.png"
          alt="Westac generic headline screenshot"
        />
        <img
          class="screenshot-cover"
          src="assets/westac/screenshot-graph1.png"
          alt="Graph of data"
        />
        <img
          class="screenshot-cover"
          src="assets/westac/screenshot-graph2.png"
          alt="Graph of year data"
        />
      </div>
    </a>

    <a href="/inidun">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>International Ideas at UNESCO</h3>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/inidun/inidun.png"
          alt="Inidun weebsite"
        />
      </div>
    </a>

    <a href="/swedeb">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>SweDeb</h3>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/swedeb/SweDeb.png"
          alt="Example data within project"
        />
      </div>
    </a>

    <a href="/infravis-sead">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>SEAD Conservation Paleobiology</h3>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/infravis-sead/seadbugs-screenshot.png"
          alt="Scrrenshot of application showing BugsCEP data"
        />
        <ul class="infrastructure-tags">
          <li class="infravis">InfraVis</li>
        </ul>
      </div>
    </a>

    <a href="/digibon">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>DigiBön</h3>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/digibon/digibon-screenshot.png"
          alt="Screenshot of map application"
        />
      </div>
    </a>

    <a href="/plagiat">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>Nätutbildning om plagiat</h3>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/kuber.png"
          alt="Humlabs light cubes"
        />
      </div>
    </a>

    <a href="/queer-ai">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>Queer AI</h3>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          style="object-fit: contain"
          src="assets/queer-ai/queer-ai.png"
          alt="Queer AI brain"
        />
      </div>
    </a>

    <a href="/aps">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>Andra Person Singular</h3>
          <h4>2023</h4>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/aps/aps_love.jpg"
          alt="Från föreställningen APS"
        />
      </div>
    </a>

    <a href="/umanista">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>Umanista</h3>
          <h4>2019–2021, 2023–</h4>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/umanista/umanista.png"
          alt="Screenshot of webpage"
        />
      </div>
    </a>
    
    <!-- ----------------------------- FINISHED PROJECTS ------------------------------------- -->
    <a href="/linguistic-landscape">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>The Language of Place-making</h3>
          <h4>2019–2022</h4>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/linguistic-landscape/LL-screenshot.png"
          alt="Screenshot of GIS-application of data in Umeå"
        />
      </div>
    </a>

    <a href="/dialectical-imaginaries">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>Dialectical Imaginaries</h3>
          <h4>2020–2021</h4>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/dialectical-imaginaries/di.png"
          alt="Screenshot of webpage"
        />
      </div>
    </a>



    <a href="/going-beyond-search">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>Going beyond search</h3>
          <h4>2019</h4>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/kuber2.png"
          alt="Screenshot of webpage"
        />
      </div>
    </a>

    <a href="/tcoir">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>The Culture of International Society</h3>
          <h4>2016–2019</h4>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/tcoir/tcoir.png"
          alt="Screenshot of webpage"
        />
      </div>
    </a>

    <a href="/digital-periegesis">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>Digital Periegesis</h3>
          <h4>2018–2021</h4>
        </div>
        <img
          class="screenshot-cover rowspan"
          src="assets/digital-periegesis/digital-periegesis.png"
          alt="Screenshot of map"
        />
        <img
          class="screenshot-cover"
          src="assets/digital-periegesis/digital-periegesis2.png"
          alt="Screenshot of map application"
        />
        <img
          class="screenshot-cover"
          src="assets/digital-periegesis/digital-periegesis-icons.png"
          alt="Showcase of icons used in map"
        />
      </div>
    </a>

    <a href="/melody">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>Melody</h3>
          <h4>2018–2021</h4>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/melody/melody.png"
          alt="Melody screenshot of webpage"
        />
      </div>
    </a>

    <a href="/iaccept">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>iAccept</h3>
          <h4>2017–2021</h4>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/iaccept/iaccept.jpg"
          alt="Screenshot of webpage"
        />
      </div>
    </a>

    <a href="/rave">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>RAVE & C-RAVE</h3>
          <h4>2015–2020</h4>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/rave+c-rave/rave.png"
          alt="Screenshot of Rave webpage"
        />
      </div>
    </a>

    <a href="/digitalamodeller">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>Digitala modeller</h3>
          <h4>2016–2019</h4>
        </div>
        <img
          class="screenshot-cover rowspan"
          src="assets/digitalamodeller/model3.png"
          alt="Wooden tool"
        />
        <img
          class="screenshot-cover"
          src="assets/digitalamodeller/model1.png"
          alt="Screenshot of wooden tool"
        />
        <img
          class="screenshot-cover"
          src="assets/digitalamodeller/model2.png"
          alt="Screenshot of 3D wooden tool"
        />
      </div>
    </a>

    <a href="/enida">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>ENIDA</h3>
          <h4>2019</h4>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/enida/enida-screenshot-1.png"
          alt="Screenshot from application of ENIDA"
        />
      </div>
    </a>

    <a href="/yrkesrum">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>Yrkesrum</h3>
          <h4>2018</h4>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/yrkesrum/yrkesrum1.png"
          alt="Screenshot of network within application"
        />
      </div>
    </a>

    <a href="/utopia">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>Utopia</h3>
          <h4>2017</h4>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/utopia/1.png"
          alt="Screenshot from game"
        />
      </div>
    </a>

    <a href="/staden">
      <div class="project-grid-tile">
        <div class="project-header-container colspan">
          <h3>Staden</h3>
          <h4>2014</h4>
        </div>
        <img
          class="screenshot-cover colspan rowspan"
          src="assets/staden-screenshot-1.png"
          alt="Screenshot of application"
        />
      </div>
    </a>

    <!--         <a href="/ships">
            <div class="project-grid-tile">
                <div class="project-header-container colspan">
                    <h3>SHiPS</h3>
                    <h4>2009 - 2011</h4>
                </div>
                <img class="screenshot-cover colspan rowspan" src="assets/default.jpg" alt="">
            </div>
        </a> -->
  </div>
</div>
