<div>
    <h2 class="sub-header">International Ideas at UNESCO<br/>INIDUN</h2>

    <div class="main-container">
        <div class="left-container">
            <p class="desc-text">
                <span class="sv">Detta forskningsprojekt använder storskalig digital 
                    textanalys för att utforska tusentals dokument producerade av Unesco, 
                    från dess grundande 1945 till 2015. Syftet med att använda den 
                    omfattande mängden texter är att förstå hur ”internationella idéer” 
                    har utvecklats och förändrats över tid.
                </span>
                <br/><br/>
                This research project uses large-scale digital text analysis to explore 
                thousands of documents produced by the United Nations Educational, 
                Scientific and Cultural Organization (UNESCO) from its foundation in 1945 to 2015. 
                The purpose of this project is to use the vast quantity of texts produced by 
                this global institution to understand how “international ideas,” 
                core concepts that define the terms of international debate, developed and 
                changed over time. Focusing on how concepts related to culture and communication 
                affected and were affected by decolonization, our approach will shed light on the 
                intellectual history of the process by which international society became global.
            </p>
            <a class="explore-button" href="https://inidun.github.io/" target="_blank">Project website ▶</a>

            <div class="box humlab">
                <h3>People @ Humlab</h3>
                <ul>
                    <li><a href="https://www.umu.se/personal/roger-mahler/">Roger Mähler</a></li>
                    <li><a href="https://www.umu.se/personal/fredrik-noren/">Fredrik Norén</a></li>
                    <li><a href="https://www.umu.se/personal/andreas-marklund/">Andreas Marklund</a></li>
                    <li><a href="https://www.umu.se/personal/marita-nilsson/">Marita Nilsson</a></li>
                </ul>
            </div>

            <div class="box cooperation">
                <h3>In cooperation with</h3>
                <ul>
                    <li>Benjamin Martin</li>
                    <li>Uppsala University</li>

                </ul>
            </div>

            <div class="box financier">
                <h3>Financier</h3>
                <div class="financier-logo">
                    <a href="https://www.vr.se/" target="_blank">
                        <img src="assets/vr.png" alt="Swedish research council logotype">
                    </a>
                </div>
            </div>
        </div>

        <div class="right-container">
            <div class="project-image">
                <img class="screenshot-image" src="assets/inidun/inidun2.jpg" />
                <span>Young people at a UNESCO club meeting, Enghien-les-Bains, 
                    France, 1957 (photo: C. Bablin, UNESCO Gallery “Education Transforms Lives”)
                </span>
            </div>

            <p class="info-box">Together we will apply cutting-edge methods of digital text analysis, 
                including topic modeling and word embedding models, to a <span>large sample of texts 
                (ca 20 million words)</span> including minutes from UNESCO's General Conference, 
                standard-setting instruments, and UNESCO's magazine Courier.</p>
            
        </div>
    </div>
</div>