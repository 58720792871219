<div>
    <h2 class="sub-header">The Language of Place-making<br/>
        Platsskapande språk. En studie av språkliga landskap</h2>
        <h4 class="year">2019–2022</h4>

    <div class="main-container">
        <div class="left-container">
            <p class="desc-text">
                <span class="sv">
                    Projektets syfte är att undersöka hur språk, när de materialiseras i 
                    vår omgivning, bidrar till att skapa offentliga rum. Med hjälp av 
                    kvalitativa och kvantitativa metoder beskriver och analyserar vi vilka 
                    språk som är synliga och vilka som inte är det, och relaterar dessa 
                    resultat till demografiska, socioekonomiska och språkliga egenskaper 
                    på olika platser. Detta gör att vi kan förstå hur urbana och rurala miljöer 
                    skapas med hjälp av majoritets-, urfolk- och minoritetsspråk. </span>
                <br/><br/>
                This project focuses on linguistic landscapes in a traditionally linguistic rich 
                area of Sweden, Norrland. The aim is to investigate how languages, as they 
                materialise in our surroundings, contribute to the making of public spaces. 
                Through a mixed-methods approach, we will describe and analyse which languages 
                are visible and which are not, and relate these findings to demographic, 
                socio-economic, educational and linguistic characteristics of different spaces. 
                This will enable us to understand how urban and rural places are constructed by 
                the use of majority, indigenous and minority languages. 
                <br/><br/><a href="https://www.umu.se/en/research/projects/the-language-of-place-making.-a-mixed-method-analysis-of-the-linguistic-landscapes/">Read more about project</a>
            </p>
            <a class="explore-button" href="https://experience.arcgis.com/experience/2cf1831bb471483e996552b651c66157?org=umu-emg" target="_blank">Explore Application ▶</a>
            
            <div class="project-image">
                <img src="assets/linguistic-landscape/LL2.png" alt="Linguistic Landscape ArcGIS application has a crowdsourcing feature so people can contribute to the map." class="screenshot-image" />
                <span>ArcGIS application screenshot of crowdsourcing feature</span>
            </div>
            
            <div class="box humlab">
                <h3>People @ Humlab</h3>
                <ul>
                    <li><a href="https://www.umu.se/en/staff/coppelie-cocq/">Coppélie Cocq</a></li>
                    <li><a href="https://www.umu.se/personal/cenk-demiroglu/">Cenk Demiroglu</a></li>
                    <li><a href="https://www.umu.se/personal/kajsa-palm/">Kajsa Palm</a></li>
                </ul>
            </div>

            <div class="box cooperation">
                <h3>In cooperation with</h3>
                <ul>
                    <li>Lena Granstedt</li>
                    <li>Urban Lindgren</li>
                    <li>Eva Lindgren</li>
                </ul>
            </div>

            <div class="box financier">
                <h3>Financier</h3>
                <div class="financier-logo">
                    <a href="https://formas.se/" target="_blank">
                        <img src="assets/formas-logo.png" alt="Formas logotype">
                    </a>
                </div>
            </div>
        </div>

        <div class="right-container">
            <div class="project-image">
                <img src="assets/linguistic-landscape/LL1.png" alt="Linguistic Landscape ArcGIS application main interface with a map, pie charts, and photos taken by the project." class="screenshot-image" />
                <span>Linguistic Landscape ArcGIS application main interface</span>
            </div>

            <p class="info-box">
                This application guides you through more than <span>6,000 photos of signs around Norrland, Sweden</span>
                and highlights their language and underlying demographics. 
                The images were documented in 2020-2021. 
                The <span>grid-based demographic data</span> is taken from SCB Register of the total 
                population (2020-12-31).
            </p>
        </div>
    </div>
</div>