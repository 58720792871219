<div>
    <h2 class="sub-header">The digital Periegesis<br/>
        tracing the places of ancient Greece and the stories associated with them</h2>
    <h4 class="year">2018–2021</h4>

    <div class="main-container">
        <div class="left-container">
            <p class="desc-text">
                <span class="sv">Detta forskningsprojekt avser att studera migration, samhällsomvandling och kris i Europa genom 
                    ett digitalt och antikt prisma. Specifikt digitaliseras den grekiska geografen Pausanias arbete 
                    Periegesis Hellados (Beskrivning av Grekland). Periegesis är en reseguide från 100-talet e.kr. 
                    för helleniska platser under romerskt herravälde. Projektet skapar en digital karta och ett 
                    geografiskt informationssystem över Pausanias Grekland. </span>
                <br/><br/>
                In this project we aim to trace, map and analyse Pausanias's spatial (re)imagining of Greece: 
                both his representation of the (human) geography of Greece and the spatial structure, or place-boundedness, of his text. 
                This is to identify and reflect on not only how Pausanias describes places and objects within them, but also the 
                spatial organisation of his narrative—how he relates places to each other. <br/>
                Pausanias doesn't simply move through space as if following a route, as he describes (and reinscribes) the layout of 
                ancient sites or locates objects (temples, statues) in situ; he also relates places or objects to others in far-flung locations 
                of the Mediterranean. And, further: he moves through time, as he attributes to those sites or objects (hi)stories of their construction, 
                reimagination or demise. Precisely because issues of space, time and identity are so bound up with each other in the Periegesis, 
                it is impossible to map this narrative by conventional means.
                <br/><br/>Read more at <a href="http://periegesis.org/" target="_blank">http://periegesis.org/</a>
            </p>

            <a class="explore-button" href="https://gis.periegesis.org/" target="_blank">Explore Application ▶</a>
            
            <div class="project-image">
                <img src="assets/digital-periegesis/digital-periegesis2.png" alt="ArcGIS application showing map of Greece">
                <span>ArcGIS application showing map of Greece</span>
            </div>
            
            <div class="box humlab">
                <h3>People @ Humlab</h3>
                <div class="two-col-people">
                    <ul>
                        
                        <li><a href="https://www.umu.se/personal/kajsa-palm/">Kajsa Palm</a></li>
                    </ul>

                    <ul>
                        <li><span>Previous</span></li>
                        <li>Anna Foka</li>
                        <li>Filip Allberg</li>
                        <li><a href="https://www.umu.se/personal/cenk-demiroglu/">Cenk Demiroglu</a></li>
                    </ul>
                </div>
            </div>

            <div class="box cooperation">
                <h3>In cooperation with</h3>
                <ul>
                    <li>Anna Foka</li>
                    <li>Elton Barker</li>
                    <li>Brady Kiesling</li>
                    <li>Kyriaki Konstantinidou</li>
                    <li>Linda Talatas</li>
                    <li>Nasrin Mostofian</li>
                    <li>David McMeekin</li>
                    <li>Uppsala University</li>
                </ul>
            </div>

            <div class="box financier">
                <h3>Financier</h3>
                <div class="financier-logo">
                    <a href="https://maw.wallenberg.org/" target="_blank">
                        <img src="assets/stiftelsenMAWM-logo.svgz" alt="Stiftelsen Marcus och Amalia Wallenbergs Minnesfond logo">
                    </a>
                </div>
            </div>
        </div>

        <!-- Right side -->
        <div class="right-container">

            <div class="project-image">
                <img src="assets/digital-periegesis/digital-periegesis.png" alt="ArcGIS application showing a map of the roman empire">
                <span>ArcGIS application showing a map of the roman empire</span>
            </div>

            <!-- Info box - Small text with background -->
            <p class="info-box">Building on the digitisation efforts of other initiatives, 
                specifically the <span class="text-highlight">Perseus Classical Library and Pelagios, we use the web-based platform Recogito</span> to annotate 
                (i.e. make notes on) Pausanias's text directly, thereby essentially treating the text itself as a database.</p>

            <div class="project-image">
                <img src="assets/digital-periegesis/digital-periegesis-icons.png" alt="Symbology for ArcGIS application">
                <span>Symbology created to be used in GIS-application to visualize Pausanias movement</span>
            </div>                
        </div>
    </div>
</div>