import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-melody',
  templateUrl: './melody.component.html',
  styleUrls: ['./melody.component.scss']
})
export class MelodyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
