import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enida',
  templateUrl: './enida.component.html',
  styleUrls: ['./enida.component.scss']
})
export class EnidaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
