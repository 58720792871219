<div>
    <h2 class="sub-header">
        VISP - Visible Speech
    </h2>

    <div class="main-container">
        <div class="left-container">
            <p class="desc-text">
                <span class="sv">Forskningsplattformen Visible Speech (VISP) är en infrastruktur som 
                    under hela uppbyggnadsprocessen designats för att möjliggöra en hantering av 
                    ljudinspelningar av personer som talar, i enlighet med GDPR och kraven vad 
                    gäller IT-säkerhet. Plattformen kommer möjliggöra och förenkla nationella 
                    samarbeten eftersom det går att arbeta direkt mot det ljudinspelade materialet 
                    utan att riskera att det sprids vidare. VISP har två nivåer, där den yttre nivån 
                    är en öppen plattform som inte lagrar data och den inre nivån ger forskare en 
                    säker miljö att ladda upp, göra akustisk signalanalys, lyssna på och notera, 
                    samt analysera talmaterial.</span>
                <br/><br/>
                The Visible Speech (VISP) infrastructure provides access to a centralized environment for 
                spoken language research that strives to meet both researchers' need for efficient workflows 
                and the legislator's requirement of secured data management. The infrastructure offers the 
                research community a unified environment for conducting reproducible speech signal 
                processing in an environment that supports national and international collaborations. 
                In fact, the VISP platform offers the most comprenehsive set of publically available 
                speech and voice analysis procedures in the world in its framework. 
                
                <br/><br/>
                Digital archiving of hosted projects is also made easier by a uniform, documented, 
                and transparent directory structure for all projects in VISP, which also reduces 
                barriers to making data available in accordance with the FAIR principles.
            </p>

            <a class="explore-button" href="https://visp.humlab.umu.se" target="_blank">Explore Applicaton ▶</a>
            
            <div class="logo-project">
                <img src="assets/visp/VISP-logo-black.png" alt="VISP logotype" />
            </div>

            <div class="box humlab">
                <h3>People @ Humlab</h3>
                <ul>
                    <li><a href="https://www.umu.se/personal/johan-von-boer/">Johan von Boer</a></li>
                    <li><a href="https://www.umu.se/personal/tomas-skotare/">Tomas Skotare</a></li>
                    <li><a href="https://www.umu.se/personal/kajsa-palm/">Kajsa Palm</a></li>
                </ul>
            </div>

            <div class="box cooperation">
                <h3>In cooperation with</h3>
                <ul>
                    <li>Fredrik Karlsson</li>
                    <li>Linda Sandström</li>
                    <li><a href="https://www.umu.se/institutionen-for-klinisk-vetenskap/" target="_blank">Department of Clinical Sciences</a></li>
                </ul>
            </div>

            <div class="box financier">
                <h3>Financier</h3>
                <div class="financier-logo">
                    <a href="https://www.vr.se/" target="_blank">
                        <img src="assets/vr.png" alt="Swedish research council logotype"></a>
                        
                    <a href="https://www.umu.se/humlab/" target="_blank">
                        <img src="assets/umu-logo-EN.png" alt="Umeå University logotype"></a>
                </div>
            </div>
            <div class="project-image">
                <img src="assets/visp/visp-screenshot-1.png" alt="VISP user interface"/>
                <span>EMU-WebApp speech analysis tool is one of the integrated applications in the VISP system.</span>
            </div>
        </div>
        

        <div class="right-container">
            <div class="mov">
                <video controls>
                    <source src="assets/visp/visp.mov" type="video/mp4">
                </video>
            </div>
            <div class="project-image">
                <img src="assets/visp/visp.png" alt="VISP user interface screenshot of EMU-WebApp"/>
                <span>EMU-WebApp speech analysis tool is one of the integrated applications in the VISP system.</span>
            </div>
            <p class="info-box">
                Access to the <span class="text-highlight">secure areas of VISP</span> is granted to projects nationally following 
                a review process of the etical approval for the project, and an agreement on 
                the processing of person identifiable information under the GDPR. 
                But <span class="text-highlight">please don't hesitate to get in touch with one of the VISP 
                team</span> if you think the system might be useful to you.
            </p>
            <div class="project-image">
                <img src="assets/visp/screenshot5.png" alt="VISP user interface screenshot integration with GitLab"/>
                <span>All data in the VISP system is version controlled through integration with GitLab.</span>
            </div>

            <div class="box infrastructure">
                <h3>Infrastructure</h3>
                <a href="https://sweclarin.se/" target="_blank">
                    <img src="assets/sweclarin-logo.png" alt="SweClarin logotype"></a>
            </div>

            <div class="box tags">
                            <h3>Development techniques<!--Utvecklingsmetoder --></h3>
                            <div class="tag">
                                <span>Docker</span>
                                <span>Jupyter</span>
                                <span>Two-factor authentication</span>
                            </div>
                        </div>
            
        </div>
    </div>
</div>