<div>
    <h2 class="sub-header">Queer Perspectives on Automated Facial Analysis Technology <br/>(Queer AI)</h2>
    <h4 class="year">2022 - </h4>
    <div class="main-container">
        <div class="left-container">

            <p class="desc-text">
                <span class="sv">Detta projekt syftar till att identifiera aktuell forskning om 
                    skärningspunkterna mellan FA (Automated facial analysis) och queerpositioner 
                    och identifieringar, och/eller forskning om FA från queerteoretiska utgångspunkter. 
                    I detta ingår att genomföra en litteraturgenomgång av aktuell forskning inom området. 
                    Vi kommer också att etablera ett lokalt, nationellt och internationellt 
                    forskningsnätverk som är engagerat kring frågor om risker och möjligheter med 
                    automatiserad ansiktsanalysteknik (FA) ur queerperspektiv. Humlabs offentliga Talk- 
                    and Share-program kommer att fungera som en plattform för att bygga och koordinera ett 
                    nätverk med inbjudna talare från forskare inom genusvetenskap, digital humaniora, informatik, 
                    datavetenskap, medievetenskap, etnologi och andra relevanta områden och discipliner.</span>
                
                <br/><br/><a href="https://www.umu.se/nyheter/beviljas-medel-for-utrustning-som-framjar-digital-humaniora_11754088/">Läs mer: Humlab beviljas medel för utrustning som främjar digital humaniora.</a>
                <br/><br/>

                Automated facial analysis (FA) technologies - such as facial detection and facial recognition - 
                stand out as central in discussions about Artificial Intelligence's (AI) impact on human beings. 
                AI is intertwined with both the most mundane and the most critical aspects of human life, 
                and image detection and classification represents a pertinent domain where we can see a 
                tight coupling of human identity and computation. In previous research on automatic gender 
                recognition, the classification of gender by FA technologies, has raised potential concerns 
                around issues of racial and gender bias (e.g. Schuerman et al 2019). Research on FA from queer 
                perspectives is still limited, but the results in studies with focus on queer identity and 
                perspectives points towards potential risks. For an example, Schuerman et al. (2019) found 
                that FA services performed consistently worse on transgender individuals and were universally 
                unable to classify non-binary genders. 
                <br/><br/>
                </p>

            <a class="explore-button" href="https://play.umu.se/playlist/dedicated/105250/0_qwbiscsy/0_lqdnclpb" target="_blank">Humlab Talk ▶</a>
            
            <div class="box humlab">
                <h3>People @ Humlab</h3>
<!--                 add this div class=two-col-people if previous humlabers should be included
 -->                
                    <ul>
                        <li><a href="https://www.umu.se/en/staff/coppelie-cocq/">Coppélie Cocq</a></li>
                        <li><a href="https://www.umu.se/personal/karin-danielsson/">Karin Danielsson</a></li>
                        <li><a href="https://www.umu.se/personal/evelina-liliequist/">Evelina Liliequist</a></li>
                    </ul>
                                
            </div>



            <div class="box financier">
                <h3>Financier</h3>
                <div class="financier-logo">
                    <p>The Faculty of Arts and Humanities, Umeå University</p>
                </div>
            </div>
        </div>

        <!-- Right side -->
        <div class="right-container">

            <!-- Info box - Small text with background -->
            <p class="info-box"><span>Publications:</span><br/>
                
                <br/>
                <li>Danielsson, K., Tubella, A. A., Liliequist, E., & Cocq, C. (2023). Queer Eye on AI: binary systems versus fluid identities. In Handbook of Critical Studies of Artificial Intelligence (pp. 595-606). Edward Elgar Publishing.</li>
                <br/>
                <li>Liliequist, E., Tubella, A. A., Danielsson, K., & Cocq, C. (2023). Beyond the Binary - Queering AI for an Inclusive Future. interactions, 30(3), 31-33.</li>

            </p>

            <div class="info-box events">Coming events:
                <ul>
                    <li><a href="https://www.umu.se/en/humlab/program-activities/queer-futures-of-ai/" target="_blank">Conference "Queer futures of AI? Possibilities, constraints, and risks"</a></li>
           
                </ul>
            
            </div>

            <div class="info-box events">Previous events:
                <ul>
                    <li><a href="https://www.umu.se/en/events/humlab-talk-queer-in-ai_11398958/" target="_blank">Queer in AI</a></li>
                    <li><a href="https://www.umu.se/en/events/humlab-talk-how-we-teach-algorithms-identity-_11162212/" target="_blank">How We Teach Algorithms Identity</a></li>
                    <li><a href="https://www.umu.se/en/events/humlab-talk-the-whole-thing-smacks-of-gender_11286200/" target="_blank">The Whole Thing Smacks Of Gender</a></li>
                </ul>
            
            </div>
        </div>
    </div>
</div>