import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-queer-ai',
  templateUrl: './queer-ai.component.html',
  styleUrls: ['./queer-ai.component.scss']
})
export class QueerAiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
