import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staden',
  templateUrl: './staden.component.html',
  styleUrls: ['./staden.component.scss']
})
export class StadenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
