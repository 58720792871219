<div>
    <h2 class="sub-header">Andra Person Singular</h2>
    <h4 class="year">2023</h4>
    <div class="main-container">
        <div class="left-container">

            <p class="desc-text">
                <span class="sv">
                    En tankekontrollerande mellanstadieföreställning om suggestion och forcerade val, väl maskerat som ett spel.
                    <br/><br/>
                    Andra person singular är en föreställning skräddarsydd för barn (och vuxna) som älskar rollspel och tv-spel. I skapandet av  föreställningen har Skuggteatern samarbetat med spelutvecklare, rollspelsskapare, spelande barn och Humlab vid Umeå universitet för att ingjuta spelandets form i scenkonsten.
                    <br/><br/>
                </span>
                I Andra person singular förvandlas publiken till ett kollektivt DU som färdas genom berättelsen  genom att göra val. Istället för att spela teater för dig är föreställningen ett spel som du spelar, skådespelaren är en spelledare som guidar dig genom ett äventyr som ingen av er vet hur det ska sluta.
                <br/><br/>
                Efter föreställningen kan publiken sedan spela den digitala versionen av föreställningen och utforska alla möjliga vägval online. Det finns även lärarmaterial och ett skapande skola paket som kombinerar programmering och skapande så eleverna kan skapa sin egen interaktiva berättelse. Humlab har bidragit med utvecklingen av den digitala versionen av spelet.
                <br/><br/><a href="https://www.skuggteatern.com/andra-person-singular" target="_blank">Läs mer på Skuggteaterns webb</a>
            </p>

            <a class="explore-button" href="https://demo.humlab.umu.se/aps/ " target="_blank">Prova spelet ▶</a>
            
<!--             <div class="project-image">
                <img src="" alt="">
                <span>Image text</span>
            </div> -->
            
            <div class="box humlab">
                <h3>Personer på Humlab<!-- Personer på Humlab --></h3>
<!--                 add this div class=two-col-people if previous humlabers should be included
               <div class="two-col-people">  --> 
                    <ul>
                        <li><a href="https://www.umu.se/personal/mattis-lindmark/">Mattis Lindmark</a></li>
                        <li><a href="https://www.umu.se/personal/marita-nilsson/">Marita Nilsson</a></li>
                    </ul>

<!--                     <ul>
                        <li><span>Previous</span></li>
                        <li>Add names of previous humlabers</li>
                    </ul> 
                </div>   -->             
            </div>

            <div class="box cooperation">
                <h3>Samarbetspartners</h3>
                <ul>
                    <li>Love Ersare</li>
                    <li>Emil Nilsson-Mäki</li>
                    <li>Louise Ölund</li>
                    <li>Skuggteatern</li>
                </ul>
            </div>

            <div class="box financier">
                <h3>Finansiär<!-- Finansiärer --></h3>
                <div class="financier-logo">
                    <a href="https://www.skuggteatern.com/" target="_blank">
                        <img src="assets/skuggteatern.jpg" alt="Skuggteatern logotyp.">
                    </a>
                </div>
            </div>
        </div>

        <!-- Right side -->
        <div class="right-container">

            <div class="project-image">
                <img src="assets/aps/aps_love_2.jpg" alt="Från föreställningen i ett klassrum.">
                <span>Love Ersare som inspirationsföreläsaren Sören Mesmer.</span>
            </div>

            <!-- Info box - Small text with background -->
            <p class="info-box">Skuggteatern skapade en egen webbplats där APS-metoden och det fiktiva företaget marknadsförs:  https://www.apsmetoden.se/ Här nedan visas en kort reklamfilm.</p>
            
            <!-- Videoframe for example Youtube videos of project -->
            <div class="videoframe">
                <iframe src="https://www.youtube.com/embed/ojbh2QWGHco" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>

<!--             <div class="box infrastructure">
                <h3>Infrastructure</h3>
                <a href="" target="_blank">
                    <img src="assets/g" alt=""></a>
            </div> -->

            <div class="box tags">
                <h3>Development techniques<!--Utvecklingsmetoder --></h3>
                <div class="tag">
                    <span>Twine</span>
                    <span>Html</span>
                    <span>JavaScript</span>
                </div>
            </div>
        </div>

    </div>
</div>