<div>
    <h2 class="sub-header">The Culture of International Society <br/> TCOIR
    </h2>
    <h4 class="year">2016–2019</h4>

    <div class="main-container-one-col">
            <p class="desc-text">
                <span class="sv">
                    Detta projekt använder kulturfördrag – bilaterala överenskommelser 
                    mellan stater som främjar och reglerar samarbete och utbyte inom de 
                    livsområden vi kallar kulturella eller intellektuella – som en historisk 
                    källa för att utforska kulturbegreppets internationella historia under 1900-talet.
                </span>
                <br/><br/>
                This project uses cultural treaties – bi-lateral agreements among states that 
                promote and regulate cooperation and exchange in the fields of life we call 
                cultural or intellectual – as a historical source with which to explore the 
                international history of the culture concept in the twentieth century.
                <br/><br/>
                The project's multimethod approach makes use of methods associated with the 
                digital humanities to examine bilateral cultural agreements from 1919, when 
                such agreements came into frequent use, to 1972, by which time they regulated 
                a fully global network of cultural relations. I approach these treaties through 
                two data sets. Basic information, or “metadata” (countries, date, topic, etc.) 
                for cultural agreements is available through the electronic World Treaty Index. 
                We access the content of these agreements through a large sample of treaty texts 
                from  countries across the world, which we have assembled and curated. Through 
                purpose-built digital tools, we use various forms of quantitative analysis to 
                analyze, visualize, and explore these datasets. In parallel, the project explores 
                the historical process by which a certain class of treaties came to be classified 
                as “cultural” in the first place.
            </p>

            <a class="explore-button" href="https://www.umu.se/forskning/projekt/the-culture-of-international-society/" 
            target="_blank">Read More ▶ <!-- Läs mer --></a>

            <div class="box humlab">
                <h3>People @ Humlab<!-- Personer på Humlab --></h3>
                    <ul>
                        <!-- Remove people not included in project -->
                        <li><a href="https://www.umu.se/personal/andreas-marklund/">Andreas Marklund</a></li>
                        <li><a href="https://www.umu.se/personal/roger-mahler/">Roger Mähler</a></li>
                    </ul>              
            </div>

            <div class="box cooperation">
                <h3>In cooperation with<!-- Samarbetspartners --></h3>
                <ul>
                    <li>Benjamin Martin</li>
                    <li>Uppsala University</li>
                </ul>
            </div>

            <div class="box financier">
                <h3>Financier<!-- Finansiärer --></h3>
                <div class="financier-logo">
                    <a href="https://www.rj.se/" target="_blank">
                        <img src="assets/riksbankens-jubileumsfond.png" alt="Stiftelsen Riksdagens Jubileumsfond">
                    </a>
                </div>
            </div>
        
    </div>
</div>