<div id="baseplate">
  <div id="header">
    <nav id="tilescape-button">
      <!--<a href="/"><ion-icon name="grid-outline"></ion-icon></a>-->
    </nav>
    <div class="header">
      <a href="http://umu.se" target="_blank">
        <img src="assets/umu-logo-left-EN.png" alt="Umeå University logo"></a>
        <h1><a routerLink="/">Humlab Showcase</a></h1>
    </div>
    <hr/>
  </div>
  
  <router-outlet></router-outlet>
  <footer>
    <a href="https://www.umu.se/humlab"><img src="assets/humlab_logo_neg_en.png" alt="UMU Humlab logo"></a>
    
    <ul>
      <li><a href="https://www.instagram.com/humlab/"><img src="assets/icons8-instagram.svg" alt="Humlab Instagram logo"></a></li>
      <li><a href="https://www.facebook.com/Humlabumea/"><img src="assets/icons8-facebook.svg" alt="Humlab Facebook logo"></a></li>
      <li><a href="https://twitter.com/humlab"><img src="assets/icons8-twitter.svg" alt="Humlab Twitter logo"></a></li>
    </ul>
  </footer>
</div>