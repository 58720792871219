<div>
    <h2 class="sub-header">Nätutbildning om plagiat</h2>
    <h4 class="year">2022</h4>
    <div class="main-container-one-col">

            <p class="desc-text">
                <span class="sv">Nätutbildning om plagiat: En universitetsövergripande, 
                    SCORM-paketerad utbildning om plagiat med syfte att informera, 
                    utbilda och certifiera studenter i Canvas.</span>
                <br/><br/>
                The plagiarism project will produce an eLearning compliance training which 
                is made in a multi-modal format (that is, with video, audio, images, text 
                and interactive exercises). Students will become thoroughly acquainted with 
                what is and what is not plagiarism. The burden of informing students about 
                plagiarism will be lifted from the individual tutor ensuring that all students 
                will get the same input. Students can focus on their own academic writing in 
                full knowledge that they have certified their understanding and training about Plagiarism.
                
            </p>

<!--             <a class="explore-button" href="" target="_blank">Explore Application ▶</a>
 -->

 <!-- Videoframe for example Youtube videos of project -->
 <div class="videoframe">
    <iframe id="kaltura_player" src="https://api.kaltura.nordu.net/p/296/sp/29600/embedIframeJs/uiconf_id/23449664/partner_id/296?iframeembed=true&playerId=kaltura_player&entry_id=0_ay3ltq4u&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_cy7nzf4e" width="400" height="285" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation" frameborder="0" title="Plagiarisn-humlab">

    </iframe>
</div>
            <div class="box humlab">
                <h3>People @ Humlab</h3>
                    <ul>
                        <li><a href="https://www.umu.se/personal/satish-stromberg/">Satish Strömberg</a></li>
                    </ul>            
            </div>

            <div class="box cooperation">
                <h3>In cooperation with</h3>
                <ul>
                    <li>Elena Lindholm</li>
                    <li>Institutionen för språkstudier</li>
                </ul>
            </div>

            <div class="box financier">
                <h3>Financier</h3>
                <div class="financier-logo">
                    <p>Umeå Universitet, PUNKTUM</p>
                </div>
            </div>

            
            
            <!-- Info box - Small text with background -->
            <div class="info-box">
                In summary:
                <ul>
                    <li>inform students about what plagiarism is and how to avoid it</li>
                    <li>inform students about how to paraphrase and cite original sources</li>
                    <li>allow students to practice their understanding with interactive activities and in-built tests</li>
                    <li>certify that students completed the eLearning</li>
                    <li>ensure that the entire student body at Umeå University will receive the same high-quality training and insights about plagiarism</li>
                    <li>mean less burden and time for teachers and the disciplinary committee if and when a plagiarism incident is discovered</li>
                </ul>
            </div>
        </div>
</div>