<div>
    <h2 class="sub-header">Staden<br/>An interactive video installation of a city rendered through dance</h2>
    <h4 class="year">2014</h4>

    <div class="main-container">
        <div class="left-container">
            <p class="desc-text">
                <span class="sv">
                    Dokumentationen av projektet Staden är skapad utifrån en idé om att en 
                    stad består av parallella, pågående skeenden. Nomodaco, i samarbete 
                    med Humlab och produktionsbolaget Lampray presenterar ett interaktivt 
                    videoformat, som kan upplevas när som helst på nätet. Staden ingick i 
                    Umeå 2014:s webbsändnings-program och ambitionen var att överföra 
                    föreställningens kärna till ett nytt format, som rymmer andra 
                    förutsättningar och möjligheter. Genom nätet förflyttas en exklusiv 
                    och tidsbestämd händelse till en mer allmän, flexibel och personlig 
                    upplevelse.
                </span>
                <br/><br/>
                This is the end result of an artistic research project developed in collaboration 
                with the dance company <a target="_blank" href="https://www.nomodaco.com/">Nomodaco</a>. 
                It is a video based interactive art installation. 
                <br/><br/>
                Read more at the <a target="_blank" href="http://www.nomodaco.com/production/discover-the-city/">Nomodaco project site</a>
            </p>
            <a class="explore-button" href="https://staden.humlab.umu.se/" target="_blank">Explore Application ▶</a>
            
            <div class="box humlab">
                <h3>People @ Humlab</h3>
                <div class="two-col-people">
                    <ul>
                        <li><a href="https://www.umu.se/personal/johan-von-boer/">Johan von Boer</a></li>
                    </ul>

                    <ul>
                        <li><span>Previous</span></li>
                        <li>Karin Jangert</li>
                    </ul>
                </div>
            </div>

            <div class="box cooperation">
                <h3>In cooperation with</h3>
                <ul>
                    <li>Umeå Kommun</li>
                    <li>Nomodaco</li>
                    <li>Lampray</li>
                </ul>
            </div>
        </div>
        <div class="right-container">
            <div class="project-image">
                <img src="assets/staden-screenshot-2.png" />
                <span>Screenshot from application</span>
            </div>
            <div class="project-image">
                <img src="assets/staden-screenshot-3.png" />
                <span>Screenshot from application</span>
            </div>
        </div>
    </div>
</div>