<div>
    <h2 class="sub-header">Umanista</h2>
    <h4 class="year">2019–2021, 2023</h4>

    <div class="main-container-one-col">

            <p class="desc-text">
                Umanista var ett pedagogiskt utvecklingsprojekt som handlade om att försöka hitta en ny form 
                av kontinuerlig kompetensutveckling för lärare inom den Humanistiska fakulteten.
                Genom en öppen, digital lärmiljö för lärare, där vi samtalade, delade med oss av 
                erfarenheter, visade goda exempel och framför allt lärde av varandra, ville vi skapa 
                en ny form för högskolepedagogisk utveckling. Målet var att vi därigenom få till stånd 
                en mer effektiv, hållbar och kollegialt underbyggd form av pedagogisk kompetensutveckling, 
                som också har potential att positivt inverka på lärares arbetsmiljö. Navet i den 
                digitala lärmiljön var en publik blogg, där olika former av material 
                tillgängliggjordes och diskuterades.
                <br/><br/><a href="https://www.umu.se/humlab/utbildning/umanista/" target="_blank">Läs mer på umu.se</a>
                <br/><a href="https://www.youtube.com/channel/UCos9QhMGTltPyhPq8OWsYRw" target="_blank">Umanista på YouTube</a>
                <br/><a href="https://twitter.com/umanistaumu" target="_blank">Umanista på Twitter</a>
            </p>

            <a class="explore-button" href="https://umanista.se/" target="_blank">Utforska Umanistas hemsida ▶</a>
            
            

            <div class="box humlab">
                <h3>Personer på Humlab</h3>
<!--                 add this div class=two-col-people if previous humlabers should be included
 -->                <div class="two-col-people">
                    <ul>
                        <li><a href="https://www.umu.se/personal/jon-svensson/">Jon Svensson</a></li>
                    </ul>

                    <ul>
                        <li><span>Previous</span></li>
                        <li>Stefan Gelfgren</li>
                    </ul>
                </div>                
            </div>

            <div class="box cooperation">
                <h3>Samarbetspartners</h3>
                <ul>
                    <li>Stefan Gelfgren</li>
                    <li>Jenny Eklöf</li>
                    <li>Humanistisk Fakultet</li>
                </ul>
            </div>

            <div class="box financier">
                <h3>Finansiärer</h3>
                <div class="financier-logo">
                    <p>Umeå Universitet, PUNKTUM</p>
                </div>
            </div>
</div>