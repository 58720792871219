<div>
  <h2 class="sub-header">Swedish Parliamentary Debates <br/> SweDeb</h2>
  <div class="main-container">
      <div class="left-container">

          <p class="desc-text">
              <span class="sv">Detta forskningsinfrastrukturprojekt syftar till att tillgängliggöra 
                de nyligen annoterade svenska riksdagsdebatterna för det bredare forskarsamhället, 
                från åtminstone 1920 till idag. Projektet kommer att utveckla ett publikt användargränssnitt 
                - SweDeb - som ska göra det möjligt för forskare att få access, filtrera samt att utforska detta 
                unika material med hjälp av olika metoder genom detta online-verktyg</span>
              <br/><br/>
              This research infrastructure project aims to make the recently annotated Swedish parliamentary 
              debates available to a broader research community, from at least 1920 to the present. 
              The project will develop an online system - SweDeb - that will enable researchers to access, 
              filter, and explore this unique material with various methods through this online tool.
<!--               <br/><br/><a href="https://" target="_blank">Read more</a>
 -->          </p>

<!--           <a class="explore-button" href="" target="_blank">Explore Application ▶</a>
 -->          
<!--           <div class="project-image">
              <img src="" alt="">
              <span></span>
          </div> -->
          
          <div class="box humlab">
              <h3>People @ Humlab<!-- Personer på Humlab --></h3>
<!--                 add this div class=two-col-people if previous humlabers should be included
-->                <div class="two-col-people">
                  <ul>
                      <li><a href="https://www.umu.se/personal/kajsa-palm/">Kajsa Palm</a></li>
                      <li><a href="https://www.umu.se/personal/rebecka-weegar/">Rebecka Weegar</a></li>
                      <li><a href="https://www.umu.se/personal/roger-mahler/">Roger Mähler</a></li>
                      <li><a href="https://www.umu.se/personal/marita-nilsson/">Marita Nilsson</a></li>
                  </ul>

                  <ul>
                      <li><span>Previous<!-- Tidigare Humlabbare --></span></li>
                      <li>Fredrik Norén</li>
                  </ul>
              </div>                
          </div>

          <div class="box cooperation">
              <h3>In cooperation with<!-- Samarbetspartners --></h3>
              <ul>
                  <li>Fredrik Norén - Malmö University</li>
                  <li>Johan Jarlbrink - Umeå University</li>
              </ul>
          </div>

          <div class="box financier">
              <h3>Financier<!-- Finansiärer --></h3>
              <div class="financier-logo">
                  <a href="https://www.umu.se/en/" target="_blank">
                      <img src="assets/umu-logo-EN.png" alt="Umeå University logotype">
                  </a>
                  <a href="https://www.umu.se/en/humlab/">
                    <img src="assets/humlab_logo_en.png" alt="Umeå University Humlab logotype">
                  </a>
              </div>
          </div>
      </div>

      <!-- Right side -->
      <div class="right-container">

        <div class="project-image">
          <img src="assets/swedeb/SweDeb.png" alt="Imgage displaying some of the data withing this project">
          <span>Representation of the data within this project</span>
        </div>

          <!-- Info box - Small text with background -->
          <p class="info-box">The Swedish parliamentary debates are <span>stored in the Riksdag records 
            (Kammarens protokoll)</span>, and are currently being annotated by the <a style="text-decoration: underline;"href="https://swerik-project.github.io/website/">Swerik project</a>, which is 
            also creating a database of all Swedish members of parliament with relevant metadata. 
            The SweDeb interface builds on this data. </p>
          
      </div>

  </div>
</div>